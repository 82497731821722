@import "../variables.scss";

.verified-modal-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 230px;
  z-index: 1000;
}

.verified-modal-avatar-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 45%;
}

.verified-modal-info-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 55%;
  padding-left: 20px;

  h5 {
    font-size: 1.5rem;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }

  p {
    font-size: 1rem;
    color: var(--secondary-font-color);
    margin-bottom: -5px;

    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
}

.verified-modal-sub-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60%;
  p {
    font-size: 1rem;
    color: var(--secondary-font-color);
    margin-bottom: -5px;
  }
}

.verified-modal-btn {
  height: 25px;
  font-size: 0.7rem !important;
  padding: 0.2rem 0.5rem !important;

  @media screen and (max-width: 768px) {
    font-size: 0.6rem !important;
  }
}

.verified-modal-checkmark {
  color: var(--badge-success);
  width: 10%;
}

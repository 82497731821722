/* region modal container and size */
.region-modal .modal-dialog .modal-content {
  max-width: 100%;

  @media screen and (max-width: 2000px) {
    min-width: 800px !important;
  }

  @media screen and (max-width: 1000px) {
    min-width: 100% !important;
  }

  @media screen and (max-width: 576px) {
    min-width: 100% !important;
  }
}

#interactive-map-gradient {
  --color-bot: var(--hover-color);
  --color-stop: var(--primary-color);
}

/* main svg color and container */
g {
  fill: url(#interactive-map-gradient);
  stop-color: var(--font-color) 40% var(--secondary-font-color) 80%;
  svg {
    width: 100%;
    height: 100%;
  }
}

/* svg shapes and objects */
.svg-circle {
  fill: var(--secondary-font-color);
  animation: circle-pulsate 2s infinite;
  &:hover {
    fill: var(--font-color);
    cursor: pointer;
  }
}

.svg-circle-hovered {
  fill: var(--font-color);
  cursor: pointer;
}

.svg-border {
  stroke-opacity: 0;
  fill: black;
  fill-opacity: 0;
  stroke-width: 75;
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s ease-in;
}

.svg-border-hovered {
  stroke-opacity: 1;
  stroke-width: 75;
  stroke: var(--font-color);
  fill-opacity: 0;
}

@keyframes border-pulsate {
  0% {
    stroke: var(--font-color);
  }
  50% {
    stroke: var(--hover-color);
  }
  100% {
    stroke: var(--font-color);
  }
}

@keyframes circle-pulsate {
  0% {
    fill: var(--font-color);
  }
  50% {
    fill: var(--secondary-font-color);
  }
  100% {
    fill: var(--font-color);
  }
}

// .svg-world-rect {
//   fill: var(--hover-color);
//   cursor: pointer;

//   &:hover {
//     fill: var(--secondary-font-color);
//   }
// }

// .svg-world-text {
//   fill: var(--primary-color);
//   font-weight: 600;
//   font-size: 300px;
//   text-shadow: none;
//   cursor: pointer;
//   transform: (6500, 3000) scale(1, -1);
// }

/* tab navigation for region selection */
.nav-item {
  padding-left: 5px;
  padding-bottom: 1px;
  a {
    color: var(--secondary-font-color) !important;
    background-color: var(--primary-color) !important;
  }

  @media screen and (max-width: 960px) {
    margin: 0px !important;
    padding: 1px !important;
  }
}
.nav-link.active {
  color: white !important;
  background-color: var(--font-color) !important;
  font-family: "Roboto", sans-serif !important;
  text-shadow: none;
  border-color: var(--primary-color) !important;
}

.nav-link.disabled {
  color: grey !important;
  font-family: "Roboto", sans-serif !important;
  text-shadow: none;
}

.nav-link {
  color: var(--font-color) !important;
  @media screen and (max-width: 960px) {
    padding: 2px 5px 1px 5px !important;
    margin: 0px !important;
    font-size: 0.6rem;
  }
}

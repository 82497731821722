@import "../variables.scss";

.btn-navbar-wrapper ul {
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  right: 0px;
  transform: translate(-50%, -50%);
}

.btn-navbar-wrapper ul li {
  list-style: none;
  margin: 0 2px;
  cursor: pointer;
}

.btn-navbar-wrapper ul li {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 35px;
  background: var(--background-color);
  border-radius: 50%;
  font-size: 30px !important;
  color: var(--font-color);
  transition: 0.5s;
}

.btn-navbar-wrapper ul li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--font-color);
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.btn-navbar-wrapper ul li:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--font-color);
}

.btn-navbar-wrapper ul li:hover {
  color: var(--font-color);
  box-shadow: 0 0 5px var(--font-color);
  text-shadow: 0 0 5px var(--font-color);
}

.btn-navbar-text {
  color: var(--font-color);
  padding-top: 8px;
  padding-left: 5px;
}

.navbar-icon {
  font-size: 30px !important;
}

.navbar-icon-text {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  text-decoration: none;
  padding-left: 12px;
}

.btn-navbar-container {
  display: flex;
  flex-direction: row;
}

:root {
  --primary-color: #035565;
  --secondary-color: #1eadc5;
  --background-color: #002236;
  --background-gradient: #034870;

  --font-color: #35d2eb;
  --secondary-font-color: #9acfd5;
  --hover-color: #19d9f9;
  --link-color: #7ca9af;
  --link-hover-color: #c3dfe3;
  --header-color: #5cccff;

  --font-severe-color: #dc3545;

  --badge-success: #28a745;
  --badge-failure: #dc3545;

  --graph-color-one: #35d2eb;
  --graph-color-two: #9acfd5;
  --graph-color-three: #35ebc7;
  --graph-color-four: #9ad5cf;

  --avatar-thumbnail-width: 60px;
  --background-image: url("https://skywatchpro.us/arclight/static/media/663bd95f8c8606efefc983e0.png");
}

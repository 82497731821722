@import "../variables.scss";

// PROFILE BUTTON ADJUSTMENTS AND STYLING
.profile-btn-container {
  background: linear-gradient(to right, #00000069, #07070767, #070707cb);
  height: 54px;
  position: relative;
}

.profile-btn-container .profile-btn-wrapper {
  padding-top: 10px;
}

.profile-btn-container .profile-btn {
  border-right: 1px rgba(black, 0.4) solid;
  max-width: 60px;
  min-width: 45px;
}

.profile-btn-container .profile-btn:hover {
  background-color: var(--primary-color);
  cursor: pointer;
}

.profile-btn-container .profile-btn.active {
  background-color: var(--primary-color);
  cursor: pointer;
}

.profile-component-container {
  background: linear-gradient(
    to bottom,
    var(--background-color),
    var(--background-gradient)
  );
  opacity: 0.8;
}

.profile-icon {
  color: var(--font-color);
}

.profile-header-text {
  transition: opacity 2s ease-in-out;
}

.animate-text {
  opacity: 0;
}

@import "../variables.scss";

.profile-header-text {
  color: var(--font-color);
}

.profile-header-container .profile-text-container {
  color: var(--font-color);
  background-color: var(--background-color);
}

.profile-header-container .profile-username-container {
  color: var(--secondary-font-color);
  background-color: var(--primary-color);
}

.profile-header-container .profile-edit-container {
  color: var(--font-color);
  background-color: var(--background-color);
}

.profile-header-container .profile-edit-container:hover {
  background-color: var(--secondary-color);
  cursor: pointer;
}

// PROFILE AVATAR ADJUSTMENTS AND STYLING
.profile-avatar-container .profile-avatar-image {
  display: block;
  min-height: 440px;
  max-height: 440px;
  width: 100%;
}

.profile-avatar-container .profile-avatar-gradient {
  position: relative;
  display: inline-block;
}

.profile-avatar-container .profile-avatar-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: radial-gradient(
    circle at center,
    rgba(0, 47, 75, 0) 0%,
    rgba(0, 0, 0, 0.7) 90%,
    rgba(9, 9, 9, 0.9) 100%
  );
}

.profile-avatar-container .profile-avatar-gradient:hover:after {
  background: none;
  cursor: pointer;
}

.profile-avatar-triangle {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 40px solid var(--background-color);
  border-left: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 40px solid var(--background-color);
  z-index: 1;
  cursor: pointer;
}

.profile-avatar-triangle:hover {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 40px solid var(--secondary-color);
  border-left: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 40px solid var(--secondary-color);
  z-index: 1;
}

.profile-avatar-icon {
  color: var(--font-color);
  position: relative;
  top: -30px;
}

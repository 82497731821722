/* ------------------------ Sidebar Map Header ------------------------ */
.sidebar-map-header {
  padding-left: 12px;
  padding-top: 10px;
  padding-bottom: 20px;
  height: 100px !important;
  background-image: linear-gradient(
    315deg,
    var(--background-color) 0%,
    var(--primary-color) 74%
  );
  border-bottom: solid 1px var(--primary-color);
  cursor: pointer;

  span {
    display: block;
  }
}

.sidebar-map-header:hover {
  padding-left: 12px;
  padding-top: 10px;
  background-image: linear-gradient(
    315deg,
    var(--background-color) 10%,
    var(--primary-color) 54%
  );
}

.sidebar-map-product-type {
  font-size: 0.9em;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
  color: var(--font-color);
}

.sidebar-map-product-name {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
  color: var(--secondary-font-color);
  font-size: 0.8em;
}

.sidebar-map-product-param {
  color: var(--secondary-font-color);
  font-size: 0.7em;
  font-style: normal !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
}

.sidebar-heading-txt {
  font-size: 0.8em !important;
}

/* ------------------------ Forecast Component ------------------------ */
.forecast-run-container {
  span {
    font-size: 0.9rem;
    font-weight: none;
  }
}

.forecast-component-dropdown-btn {
  padding: 0px !important;
  margin: 0px !important;
  font-size: 0.7rem !important;
  color: var(--primary-color) !important;
  background-color: var(--secondary-font-color) !important;
  width: 185px !important;
  height: 25px !important;
}

.dropdown-item-text {
  color: var(--secondary-font-color) !important;
  font-size: 0.7rem !important;
  z-index: 1000 !important;
}

.forecast-component-menu {
  background-color: var(--background-color) !important;
  z-index: 1000 !important;
  position: absolute !important;
}

.forecast-time-container {
  margin-right: 4px !important;
}

.time_step_btn {
  background-color: var(--primary-color) !important;
  color: var(--secondary-font-color) !important;
  font-size: 0.6rem !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 600 !important;
  margin-bottom: 5px;
  padding: 2 !important;
  width: 35px;
}

.time_step_btn:focus {
  background-color: var(--secondary-color) !important;
  color: white !important;
  font-size: 0.6rem !important;
  margin-bottom: 4px;
  width: 35px;
}

/* ------------------------  Parameter Menu ------------------------ */
.ps-menu-button {
  height: 25px !important;
  font-style: normal !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
}

.ps-submenu-content .ps-menuitem-root .ps-menu-button:focus {
  height: 25px !important;
  font-size: 0.8rem;
  font-style: normal;
  color: white !important;
}

.sidebar-item .ps-menu-button {
  span {
    font-size: 0.7rem !important;
  }
}

@import "./variables.scss";

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.container-fluid.full-height {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-container .sidebar-container {
  position: fixed;
  padding-top: 50px;
  height: 100%;

  // @media (max-width: 767px) {
  //   display: none;
  // }
}

.main-container .sidebar-container-collapsed {
  position: fixed;
  padding-top: 56px;
  width: 80px !important;
  height: 100%;

  // @media (max-width: 767px) {
  //   display: none;
  // }
}

.main-container .app-container {
  flex: 1;
  overflow-y: auto;
  width: 100%;
  padding-left: 260px;
  padding-top: 60px;
  transition: padding 0.3s ease;

  background-image: linear-gradient(to bottom, #0000006f, #0000000d),
    var(--background-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-shadow: none;

  @media (max-width: 1300px) {
    padding-left: 90px;
  }
}

.main-container .app-container-collapsed {
  flex: 1;
  overflow-y: auto;
  width: 100%;
  padding-left: 90px !important;
  padding-top: 60px;

  @media (max-width: 767px) {
    padding-left: 10px;
  }
}

.main-container .navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}

/* WebKit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--background-color);
}

::-moz-scrollbar {
  width: 10px;
  height: 10px;
}

::-moz-scrollbar-thumb {
  background-color: var(--background-color);
}

/* Ensure that admin panels are showing */
.sc-kAyceB {
  display: contents !important;
}

/* Adjust background gradient for comments */
.arclight-space-y-10 {
  background: linear-gradient(var(--background-color), rgba(0, 0, 0, 0.4)),
    rgba(0, 0, 0, 0.4);
  border-image: linear-gradient(var(--secondary-color), var(--primary-color)) 30;
  border-width: 0 3px !important;
  border-style: solid;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  background-color: var(--background-color);

  span {
    color: var(--secondary-font-color);
    font-family: "Roboto", sans-serif !important;
  }
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--primary-color);
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

@import "../variables";

.sidebar-wrapper {
  height: 100%;
}

.rp-sidebar {
  flex: 1;
}

.sidebar-container {
  width: 90px !important;
  z-index: 10;

  @media screen and (max-width: 800px) {
    max-width: 90px !important;
  }
}

.ps-menuitem-root .ps-menu-button {
  background-color: var(--background-color);
  color: var(--font-color);
}

.sidebar-item-active .ps-menu-button {
  color: white !important;
}

.ps-menu-button.ps-open {
  color: var(--secondary-font-color) !important;
  background-color: var(--primary-color) !important;
}

.ps-sidebar-container {
  background-color: var(--background-color);
  z-index: 1;
}

.hr-line {
  color: var(--font-color);
}

.ps-menuitem-root {
  z-index: 999;
}

.sidebar-menu-header {
  font-size: 0.85em;
  color: rgb(143, 149, 168);
  padding-left: 15px;
}

.sidebar-menu-space {
  padding-top: 20px;
}

.hr-line {
  color: #338d99;
}

.hidden {
  display: none;
}

.ps-disabled {
  color: lightgrey !important;
}

@import "../variables.scss";

.workpage-wrapper {
  background: linear-gradient(var(--background-color), rgba(0, 0, 0, 0.2)),
    rgba(0, 0, 0, 0.2);
  border-image: linear-gradient(var(--secondary-color), var(--primary-color)) 30;
  border-width: 0 3px !important;
  border-style: solid;
  @media screen and (max-width: 768px) {
    position: fixed;
    left: 0;
  }
}

.map-controls-container {
  border-style: solid;
  border-bottom-width: 3px;
  height: 2rem;
  border-image: linear-gradient(
      to right,
      var(--secondary-color),
      var(--primary-color),
      var(--secondary-color)
    )
    100;

  background-color: var(--background-color);

  @media screen and (max-width: 768px) {
    height: 2rem;
  }
}

/* slider styles */
.slider {
  -webkit-appearance: none;
  width: 100%;
  background: var(--link-hover-color);
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1.5em;
  height: 1.5em;
  background: var(--secondary-color);
  border-radius: 5px;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 1.5em;
  height: 1.5em;
  background: var(--secondary-color);
  cursor: pointer;
}

.slider-step-btn-container {
  background-color: var(--primary-color);
  min-width: 40px;
  cursor: pointer;
  button {
    color: var(--secondary-font-color);
  }
  @media screen and (max-width: 960px) {
    min-width: 25px;
  }
}

.slider-step-btn-container:hover {
  background-color: var(--secondary-color);
  // padding-left: 4px;
  // width: 22px;
  button {
    color: white;
  }
}

.slider-play-btn {
  background-color: var(--primary-color);
  color: var(--secondary-font-color);
  cursor: pointer;
  &:hover {
    color: white;
    background-color: var(--secondary-color);
  }
}

.select-region-btn {
  color: var(--secondary-font-color);
  font-size: 1em;
  width: 1.5rem;
  height: 1.5rem;
  @media screen and (max-width: 960px) {
    font-size: 0.6em;
    width: 1.5rem;
    height: 1.5rem;
    padding-left: 20px;
  }
}

.select-region-txt {
  position: relative !important;
  bottom: 3px !important;
  @media screen and (max-width: 960px) {
    position: relative !important;
    bottom: -1px !important;
    right: 5px !important;
  }
}

.slider-gif-txt {
  padding-top: 2px;
  @media screen and (max-width: 960px) {
    font-size: 0.6em;
    position: relative;
    left: -1.2em;
  }
}

.font-size-sm {
  @media screen and (max-width: 960px) {
    transform: scale(0.8);
    position: relative;
    left: -0.7em;
  }
}

@import "../variables.scss";

.sidebar-footer {
  background: var(--background-color);
  box-shadow: 0px -1px 5px #00000063;
  border-top: 1px solid #29292960;
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
}

.footer-icon-container {
  display: flex;
  align-items: center;
}

.sidebar-footer > div:hover {
  color: var(--font-color);
  cursor: pointer;
}

.footer-icon {
  font-size: 25px !important;
  color: var(--font-color) !important;
}

.footer-icon:hover {
  color: var(--secondary-font-color) !important;
  cursor: pointer;
}

.sidebar-footer-collapsed {
  background: var(--background-color);
  box-shadow: 0px -1px 5px #00000063;
  border-top: 1px solid #29292960;
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.badge.badge-success {
  background-color: var(--badge-success);
  font-size: 10px;
}

.badge.badge-warning {
  background-color: var(--badge-warning);
  font-size: 10px;
}

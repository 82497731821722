@import "variables.scss";

.main-theme-text {
  color: var(--font-color);
}

.main-theme-header {
  color: var(--font-color);
}

.main-theme-modal-background {
  background-color: var(--background-color);
}

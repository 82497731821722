@import "../variables.scss";

.btn-premium-wrapper {
  position: absolute;
  right: 140px;
}

.btn-premium {
  background: var(--font-color);
  background: linear-gradient(
    0deg,
    var(--primary-color) 0%,
    var(--font-color) 100%
  );
  width: 100px;
  height: 20px;
  border-radius: 15px;
  padding: 5px 15px;
  font-family: "Inter", sans-serif;
  font-size: 0.8em;
  font-weight: bold;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;
}

.btn-premium {
  background: var(--font-color);
  background: linear-gradient(
    0deg,
    var(--font-color) 85%,
    var(--primary-color) 100%
  );
  width: 100px;
  height: 20px;
  line-height: 22px;
  padding: 0;
  border: none;
}

.btn-premium span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.btn-premium:before,
.btn-premium:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: var(--secondary-color);
  transition: all 0.3s ease;
}

.btn-premium:before {
  height: 0%;
  width: 2px;
}

.btn-premium:after {
  width: 0%;
  height: 2px;
}

.btn-premium:hover {
  background: transparent;
  box-shadow: none;
}

.btn-premium:hover:before {
  height: 100%;
}

.btn-premium:hover:after {
  width: 100%;
}

.btn-premium span:hover {
  color: var(--secondary-color);
}

.btn-premium span:before,
.btn-premium span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: var(--secondary-color);
  transition: all 0.3s ease;
}

.btn-premium span:before {
  width: 2px;
  height: 0%;
}

.btn-premium span:after {
  width: 0%;
  height: 2px;
}

.btn-premium span:hover:before {
  height: 100%;
}

.btn-premium span:hover:after {
  width: 100%;
}

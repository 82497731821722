.profile-activity-container .profile-text-container {
  color: var(--font-color);
  background-color: var(--background-color);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
}

.profile-activity-container .profile-feed-container {
  height: 412px;
  background: linear-gradient(
    to bottom,
    var(--background-color),
    var(--background-gradient)
  );
  border-left: 1px solid var(--background-color);
  border-right: 1px solid var(--background-color);
  opacity: 0.8;

  @media screen and (max-width: 910px) {
    padding-top: 20px;
    height: 315px;
  }
}

// text coloring
.profile-activity-header-text {
  color: var(--secondary-font-color);
}

.profile-activity-text {
  color: var(--font-color);
  position: relative;
  top: -10px;
  font-size: 0.8em;
}

.profile-activity-text > span {
  line-height: 1.2;
  display: inline-block;
  padding-top: 5px;
}

.profile-activity-text > span a {
  color: var(--link-color) !important;
  text-decoration: none;
}

.profile-activity-text > span a:hover {
  color: var(--link-hover-color) !important;
  text-decoration: none;
}

.profile-mui-btn {
  color: var(--primary-color) !important;
  background-color: var(--secondary-color) !important;
}

.profile-activity-date-container {
  color: var(--secondary-font-color);
  font-size: 0.8em;
  position: relative;
  text-align: right;
}

.profile-feed-component:hover {
  background-color: var(--background-color);
  cursor: pointer;
}

.profile-feed-btn {
  border-top: 1px solid var(--secondary-color);
}

@import "../variables.scss";

.card-img-top {
  height: 150px;
}

.card-body {
  text-decoration: none;
}

.color-text {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  font-size: 0.8em;
}

.theme-modal {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    margin: auto;
  }
}

.color-box-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.color-text {
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 0.6rem !important;
  }
}

.color-box {
  height: 20px;
  width: 20px;
  margin-bottom: 5px;
  @media screen and (max-width: 768px) {
    height: 15px;
    width: 15px;
    margin-bottom: 5px;
  }
}

.card-title {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  font-weight: bold;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border: none !important;
}

.btn-primary:hover {
  background-color: var(--secondary-color) !important;
}

.theme-cards-container {
  display: flex;
  flex-wrap: wrap;
}
.custom-card {
  width: 154px !important;
  @media screen and (max-width: 768px) {
    width: 116px !important;
  }
}

.card-theme-img {
  width: 154px !important;
  height: 154px !important;
  @media screen and (max-width: 768px) {
    width: 116px !important;
    height: 116px !important;
  }
}

@import "../variables.scss";

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.button-container button {
  width: 100%;
  background-color: var(--secondary-color);
  border: 0px;
  border-radius: 25px;
}

.button-container button:hover {
  width: 100%;
  background-color: var(--hover-color);
  border: 0px;
}

.fa-brands {
  border-radius: 50%;
  color: var(--background-color);
  background-color: var(--secondary-color);
  padding: 8px;
  font-size: 35px;
}

.fa-brands:hover {
  border-radius: 50%;
  color: var(--background-color);
  background-color: var(--hover-color);
  padding: 8px;
  font-size: 35px;
  cursor: pointer;
}

.footer-link {
  cursor: pointer;
}

.auth-modal-animation {
  animation-duration: 0.8s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.auth-modal-animation {
  opacity: 0;
  visibility: visible;
  animation-name: fadeIn;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.auth-modal form.inactive {
  opacity: 1;
  visibility: visible;
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

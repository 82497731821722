@import "../variables";

.navbar-container {
  z-index: 12 !important;
}

.navbar-top {
  background-color: var(--background-color);
  z-index: 12;
}

.navbar-text-logo {
  color: var(--font-color) !important;
  margin-left: 18px;
}

.navbar-main-logo {
  width: 60px;
  padding-left: 20px;
}

.modal-content {
  background-color: var(--background-color) !important;
  color: var(--font-color) !important;
  background-image: linear-gradient(
    125,
    var(--background-color),
    var(--gradient-color)
  ) !important;
}

.modal-title {
  margin: 0 auto;
}

.btn-modal-close {
  background-color: var(--font-color) !important;
  font-size: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
}

.modal-header {
  border-bottom-color: var(--primary-color) !important;
}

.modal-footer {
  border-top-color: var(--primary-color) !important;
  text-align: center;
  display: inline !important;
}

.footer-link {
  color: var(--secondary-font-color);
  text-decoration: none;
}
.footer-link:hover {
  color: var(--link-hover-color);
}

@import "../variables.scss";

.homepage-wrapper {
  padding: 15px;
}

.home-component-container {
  background: linear-gradient(var(--background-color), rgba(0, 0, 0, 0.2)),
    rgba(0, 0, 0, 0.2);
  border-image: linear-gradient(var(--secondary-color), var(--primary-color)) 30;
  border-width: 0 3px !important;
  border-style: solid;
}

.main-header-text {
  margin-bottom: -10px;
  color: var(--header-color);
}

/*--------------------------featured-article-panel------------------------------*/
.featured-article-container {
  padding-bottom: 20px;
  //   --bs-list-group-border-color: var(--secondary-color);
}

.featured-blog-header {
  color: var(--font-severe-color);
}

.featured-article-title {
  color: var(--font-color);
  cursor: pointer;
}

.featured-article-title:hover {
  color: var(--link-hover-color);
}

.featured-article-text-blurb {
  color: var(--secondary-font-color);
  font-size: 0.8em;
  text-align: justify;
}

.featured-article-subtext {
  color: var(--secondary-font-color);
  font-size: 0.8em;
}

.featured-article-img {
  border-radius: 5px;
  max-height: 600px;
  width: 100%;
  opacity: 0.9;
  cursor: pointer;
}

.featured-article-img:hover {
  border: solid 2px var(--secondary-color);
  opacity: 1;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

a:hover {
  color: var(--link-hover-color);
  text-decoration: none;
}

.subtext {
  padding-left: 5px;
}

.featured-article-button {
  border-color: var(--secondary-color);
  color: var(--font-color);
}

.featured-article-button:hover {
  background-color: var(--secondary-color);
  color: white;
}

/*--------------------------featured-products------------------------------*/
.featured-products-card-container {
  min-width: 180px;
}

.featured-products-card-container:hover {
  cursor: pointer;
}

.featured-products-card {
  background-color: rgba(0, 0, 0, 0.5);
}

.featured-products-card:hover {
  background-image: linear-gradient(var(--primary-color), rgba(0, 0, 0, 0.5));
  box-shadow: 0px 0px 4px var(--font-color);
}

.featured-products-card-title {
  font-size: 0.8em;
  color: var(--font-color);
}

.featured-products-card-text {
  font-size: 0.9em;
  color: var(--secondary-font-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.featured-products-img {
  border-radius: 5px;
  padding: 2px;

  @media screen and (max-width: 2000px) {
    height: 110px;
  }

  @media screen and (max-width: 1200px) {
    height: 160px;
  }

  @media screen and (max-width: 900px) {
    height: 110px;
  }

  @media screen and (max-width: 576px) {
    height: 160px;
  }
}

.featured-products-button {
  border-color: var(--secondary-color);
  color: var(--font-color);
}

.featured-products-button:hover {
  background-color: var(--secondary-color);
  color: white;
}

.card-body .btn-sm {
  height: 23px;
  font-size: 0.6em;
}

/*--------------------------recent-articles------------------------------*/
.recent-article-wrapper {
  padding-top: 15px;
  padding-bottom: 30px;
}

.recent-article-preview-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  width: 140px;
  font-size: 0.8em;
  cursor: pointer;

  @media screen and (max-width: 576px) {
    width: 400px;
  }
}

.recent-article-preview-wrapper:hover {
  background-image: linear-gradient(var(--primary-color), rgba(0, 0, 0, 0.5));
  box-shadow: 0px 0px 4px var(--font-color);
}

.article-preview-img {
  max-height: 100px;
  min-width: 140px !important;
  @media screen and (max-width: 576px) {
    max-height: 300px;
  }
}

.recent-article-date {
  color: var(--secondary-font-color);
  font-size: 0.8em;
}

.recent-article-text {
  font-size: 0.9em;
  color: var(--font-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;

  @media screen and (max-width: 576px) {
    max-width: 300px;
  }
}

.more-articles-button {
  border-color: var(--secondary-color);
  color: var(--font-color);
}

.more-articles-button:hover {
  background-color: var(--secondary-color);
  color: white;
}

/*--------------------------advertisement------------------------------*/
.banner-ad-wrapper {
  padding-top: 15px;
  padding-bottom: 30px;
}

.banner-ad-container {
  height: 100px;
}

.banner-ad-text-container {
  display: inline-block;
  position: absolute;
  width: 110px;
  right: 1px;
  top: 1px;
  padding-left: 6px;
  font-size: 0.8em;
  color: var(--font-color);
  background-color: var(--primary-color);
}

.hm-lab-svg {
  @media screen and (max-width: 576px) {
    max-width: 120px !important;
  }
}

.hm-lab-svg g {
  fill: url(#header-shape-gradient) #fff;
  transition: all 0.2s ease-in;
}

.hm-lab-svg:hover g {
  fill: var(--secondary-font-color);
}

.banner-ad-title-container {
  @media screen and (max-width: 576px) {
    max-width: 150px !important;
  }
}

.banner-ad-title-primary {
  color: var(--font-color);
  font-size: 2em;
  padding-top: 10px;

  @media screen and (max-width: 576px) {
    font-size: 1.4em;
    padding-top: 30px;
  }
}

.banner-ad-title-secondary {
  color: var(--secondary-font-color);
  font-size: 2em;
  padding-left: 20px;

  @media screen and (max-width: 576px) {
    font-size: 1.4em;
  }
}

/*--------------------------other-content------------------------------*/
.interactive-map-svg-container {
  overflow: hidden;
}

.modal .interactive-map-svg-container {
  overflow: hidden;
}

.interactive-map-svg-container g {
  fill: url(#interactive-map-gradient) #fff;
  svg {
    width: 100%;
    height: 100%;
  }
}

.modal .interactive-map-svg-container g {
  fill: url(#interactive-map-gradient) #fff;
  svg {
    width: 100%;
    height: 100%;
  }
}

.interactive-map-svg {
  transition: all 0.2s ease-in;
}

@keyframes border-pulsate {
  0% {
    stroke: var(--font-color);
  }
  50% {
    stroke: var(--hover-color);
  }
  100% {
    stroke: var(--font-color);
  }
}

.map-svg-border {
  stroke: var(--secondary-font-color);
  fill: black;
  fill-opacity: 0;
  stroke-width: 75;
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s ease-in;
}

.map-svg-border-hover {
  opacity: 1;
  fill: rgb(255, 255, 255);
  fill-opacity: 0.5;
  animation: border-pulsate 4s infinite;
}

.interactive-map-svg-right {
  transform-origin: right;
  transform: scale(2);
}

.interactive-map-svg-left {
  transform-origin: left;
  transform: scale(2);
}

.interactive-map-svg-center {
  transform: scale(2);
}

.interactive-map-svg-bottom {
  transform-origin: bottom;
  transform: scale(2);
}

.interactive-map-svg-bottom-left {
  transform-origin: bottom left;
  transform: scale(2);
}

.interactive-map-svg-bottom-right {
  transform-origin: bottom right;
  transform: scale(2);
}

#header-shape-gradient {
  --color-stop: var(--font-color);
  --color-bot: var(--primary-color);
}

#interactive-map-gradient {
  --color-stop: var(--primary-color);
  --color-bot: var(--font-color);
}

.smallMapContainer {
  width: 50%;
  height: 400px;
  border: 1px solid black;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.largeMapContainer {
  width: 90%;
  height: 90vh;
  border: 1px solid black;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.map svg {
  width: 100%;
  height: 100%;
}

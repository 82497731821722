@import "../variables.scss";

/*--------------------------side-header------------------------------*/
.sidebar-wrapper .sidebar-header {
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100px !important;
  background-image: linear-gradient(
    315deg,
    var(--background-color) 0%,
    var(--primary-color) 74%
  );
  border-bottom: solid 1px var(--primary-color);
  cursor: pointer;
}

.sidebar-wrapper .sidebar-header:hover {
  padding-left: 20px;
  padding-top: 20px;
  background-image: linear-gradient(
    315deg,
    var(--background-color) 10%,
    var(--primary-color) 54%
  );
}

.sidebar-wrapper .sidebar-header .user-pic {
  float: left;
  width: 25%;
  max-height: 70px;

  margin-right: 15px;
  padding-top: 5px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.sidebar-wrapper .sidebar-header .user-info > span {
  display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-name {
  font-size: 0.9em;
  font-weight: normal !important;
  color: var(--font-color);
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
  color: var(--secondary-font-color);
  font-size: 0.8em;
}

.sidebar-wrapper .sidebar-header .user-info .online-icon {
  color: #5cb85c;
  font-size: 0.8em;
  margin-right: 4px;
  margin-bottom: 3px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status {
  color: var(--secondary-font-color);
  font-size: 0.7em;
  margin-top: 4px;
}

.sidebar-wrapper .sidebar-header .user-info .chaser-status {
  color: var(--secondary-font-color);
  font-size: 0.7em;
  padding-left: 15px;
  margin-top: 4px;
}

.sidebar-wrapper .sidebar-header .user-info .approved-user {
  color: var(--secondary-color) !important;
  font-size: 20px !important;
  padding-left: 2px;
}

.sidebar-wrapper .sidebar-header .user-info .chaser-live-icon {
  color: var(--font-color);
  font-size: 16px !important;
  padding-right: 4px;
  margin-top: -4px;
  animation-name: example;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes example {
  from {
    color: rgb(163, 37, 37);
  }

  to {
    color: rgb(240, 78, 78);
  }
}

.sidebar-wrapper .sidebar-header .user-info .user-welcome {
  color: var(--secondary-font-color);
  font-size: 12px;
}

.sidebar-wrapper .sidebar-header .user-info .user-logo-text {
  color: var(--font-color);
}

/* collapsed sidebar styles */
.img-user-collapsed {
  overflow: hidden;
  object-fit: cover;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
